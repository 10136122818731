<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div class="bg-gray-100 p-6 rounded-lg shadow-lg w-full max-w-4xl">
        <div class="flex items-center justify-between mb-4">
          <h2 class="text-xl font-bold">Order Details</h2>
          <button class="btn btn-sm btn-circle btn-ghost right-2 top-2" @click="props.handleCloseDetails">✕</button>
        </div>
          <div>
            <div class="font-semibold mb-2">Order Information</div>
            <div class="grid grid-row-5 divide-y-2">
              <div class="flex justify-between items-center">
                <span class="text-muted-foreground my-2">Date</span>
                <span class="my-2">{{ props.selectedOrder.createdAt }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-muted-foreground my-2">Delivery Time</span>
                <span class="my-2">{{ props.selectedOrder.deliveryDate }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-muted-foreground my-2">Order ID</span>
                <span class="my-2">{{ props.selectedOrder.orderId }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-muted-foreground my-2">Status:</span>
                <span>
                  <Badge :variant="props.selectedOrder.status">
                    {{ props.selectedOrder.status.charAt(0).toUpperCase() + props.selectedOrder.status.slice(1) }}
                  </Badge>
                </span>
              </div>
              <div class="flex justify-between">
                <span class="text-muted-foreground my-2">Items:</span>
                <div class="text-right my-2">
                  <div v-for="item in props.selectedOrder.items" :key="item.sku">
                    {{ item.sku }} x {{ item.quantity }}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import Badge from './Badge.vue'

const props = defineProps({
  selectedOrder: Object,
  handleCloseDetails: Function,
});

</script>
<template>
  <div class="container justify-items-start w-full mx-auto py-8">
    <h1 class="text-2xl font-bold">Metrics</h1>
    <!-- Spinner de Carga -->
    <div v-if="loading" class="flex justify-center items-center h-64">
      <svg
        class="animate-spin h-12 w-12 text-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v8H4z"
        ></path>
      </svg>
    </div>

    <!-- Contenido de la Página -->
    <div v-else class="flex flex-col gap-6 my-2 w-full">
      <div class="border-b border-slate-400 w-full py-4">
        <h2 class="text-xl">Used Space</h2>
        <div class="stats shadow w-full">
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
            </div>
            <div class="stat-title">Buffer</div>
            <div class="stat-value text-primary">{{ data?.usedSpace.buffer }}</div>
            <div class="stat-desc">Units</div>
          </div>

          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
            </div>
            <div class="stat-title">Check-In</div>
            <div class="stat-value text-primary">{{ data?.usedSpace.checkIn }}</div>
            <div class="stat-desc">Units</div>
          </div>

          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
            </div>
            <div class="stat-title">Check-Out</div>
            <div class="stat-value text-primary">{{ data?.usedSpace.checkOut }}</div>
            <div class="stat-desc">Units</div>
          </div>

          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
            </div>
            <div class="stat-title">Kitchen</div>
            <div class="stat-value text-primary">{{ data?.usedSpace.kitchen }}</div>
            <div class="stat-desc">Units</div>
          </div>

          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/></svg>
            </div>
            <div class="stat-title">Cold</div>
            <div class="stat-value text-primary">{{ data?.usedSpace.cold }}</div>
            <div class="stat-desc">Units</div>
          </div>
        </div>
      </div>

      <div class="container mx-auto">
        <div class="border-b border-slate-400 py-4">
          <h2 class="text-xl">Stock By SKU</h2>
          <template v-if="data.stock && data.stock.length > 0">
            <div class="card overflow-x-auto">
              <table class="table bg-slate-200 text-slate-600">
                <thead class="bg-base-200">
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.stock" :key="item.sku">
                    <td>{{ item.sku }}</td>
                    <td>{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            <div class="text-center text-gray-500">There are no stock items.</div>
          </template>
        </div>

        <div class="border-b border-slate-400 py-4">
          <h2 class="text-xl">Received Orders By Hour</h2>
          <template v-if="data.ordersPerHour && Object.keys(data.ordersPerHour).length > 0">
            <div class="card overflow-x-auto">
              <table class="table bg-slate-200 text-slate-600">
                <thead class="bg-base-200">
                  <tr>
                    <th>Time</th>
                    <th>Orders</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in data.ordersPerHour" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            <div class="text-center text-gray-500">There are no received orders by hour.</div>
          </template>
        </div>

        <div class="border-b border-slate-400 py-4">
          <h2 class="text-xl">Upcoming Expirations</h2>
          <template v-if="data.upcomingExpirations && data.upcomingExpirations.length > 0">
            <div class="card overflow-x-auto">
              <table class="table bg-slate-200 text-slate-600">
                <thead class="bg-base-200">
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.upcomingExpirations" :key="item.sku">
                    <td>{{ item.sku }}</td>
                    <td>{{ item.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            <div class="text-center text-gray-500">There are no upcoming expirations.</div>
          </template>
        </div>
      </div>
      </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getMetrics } from '../api/metrics.js';

const data = ref({
  usedSpace: {
    buffer: 0,
    checkIn: 0,
    checkOut: 0,
    kitchen: 0,
    cold: 0,
  },
  stock: [],
  ordersPerHour: {},
  upcomingExpirations: []
});
const loading = ref(true);

const fetchMetrics = async () => {
  loading.value = true;
  try {
    const response = await getMetrics();
    data.value = response;
  } catch (error) {
    console.error('Error fetching metrics:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(fetchMetrics);
</script>

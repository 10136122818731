import { createRouter, createWebHistory } from 'vue-router';
import Metrics from './views/Metrics.vue';
import Orders from './views/Orders.vue';

const routes = [
    { path: '/', component: Metrics },
    { path: '/orders', component: Orders },
    { path: '/:pathMatch(.*)', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
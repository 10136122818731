<template>
    <div class="container mx-auto py-8">
      <h1 class="text-2xl font-bold mb-4">Orders</h1>
      <div class="overflow-x-auto">
        <OrderTable :handleViewMore="handleViewMore" />
      </div>
      <OpenModal v-if="selectedOrder" :selectedOrder="selectedOrder" :handleCloseDetails="handleCloseDetails" />
    </div>
</template>
  
<script setup>
  import { ref } from 'vue'
  import OpenModal from '../components/OpenModal.vue'
  import OrderTable from '../components/OrderTable.vue'
  
  const selectedOrder = ref(null)
  const handleViewMore = (order) => {
    selectedOrder.value = order
  }
  const handleCloseDetails = () => {
  selectedOrder.value = null
  }
</script>
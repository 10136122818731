<template>
  <div>
    <table class="w-full table-auto border-collapse">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2 text-left text-black">Delivery Time</th>
          <th class="px-4 py-2 text-left text-black">Order ID</th>
          <th class="px-4 py-2 text-left text-black">Items</th>
          <th class="px-4 py-2 text-left text-black">Status</th>
          <th class="px-4 py-2 text-right text-black">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="order in orders"
          :key="order.id"
          class="border-b hover:bg-muted/50 transition-colors"
        >
          <td class="px-4 py-2">{{ order.deliveryDate }}</td>
          <td class="px-4 py-2">{{ order.orderId }}</td>
          <td class="px-4 py-2">
            <SKUData :order="order" />
          </td>
          <td class="px-4 py-2">
            <Badge :variant="order.status">
              {{ order.status.charAt(0).toUpperCase() + order.status.slice(1) }}
            </Badge>
          </td>
          <td class="px-4 py-2 text-right">
            <button class="btn btn-primary btn-sm" @click="props.handleViewMore(order)">View More</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-between items-center mt-12">
      <button
        class="btn btn-primary"
        :disabled="currentPage === 1"
        @click="prevPage"
      >
        Previous
      </button>
      <span>Page {{ currentPage }}</span>
      <button
        class="btn btn-primary"
        :disabled="orders.length < pageSize"
        @click="nextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import Badge from './Badge.vue';
import SKUData from './SKUData.vue';
import { getOrders } from '../api/order.js';

const orders = ref([]);
const currentPage = ref(1);
const pageSize = 10;

const fetchOrders = async () => {
  orders.value = await getOrders(currentPage.value);
};

onMounted(fetchOrders);

const props = defineProps({
  handleViewMore: Function
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchOrders();
  }
};

const nextPage = () => {
  currentPage.value++;
  fetchOrders();
};
</script>